const config = {
  apiGateway: {
    REGION: 'us-east-2',
    INTERNAL_API_URL: 'https://z9qijsrs2m.execute-api.us-east-2.amazonaws.com/prod',
    COMPANY_API_URL: 'https://gfj93m4610.execute-api.us-east-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'taxonomy-company-1.11.3',
    HOSTNAME: 'https://company.staging.vale.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.vale.forwoodsafety.com',
    WEBSOCKET: 'wss://jiap0cb7vj.execute-api.us-east-2.amazonaws.com/staging'
  },
};

export default config;